@import "variables";

/* CSS Document */

/****************** cookie *************************/
#cookiebanner{
	position: fixed;
	z-index: 100;
	bottom: 30px;
	right: 30px;
	width: 360px;
	background: theme-color(primary);
	color: theme-color(white);
	text-align: center;
	padding: 20px;
	font-family: filson-pro, sans-serif;
    font-weight: 200;
	-moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
	-webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
	-o-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
	box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
}

@media(max-width:420px){
	#cookiebanner{
		bottom: 5%;
		left: 5%;
		right: 5%;
		width: 90%;
	}
}

#cookiebanner-close {
    border: 2px solid theme-color(white);
    border-radius: 3px;
    color: theme-color(white);
    display: block;
    line-height: normal !important;
    margin-top: 15px;
    padding: 3px 5px !important;
	-moz-transition:all linear .2s;
	-ms-transition:all linear .2s;
	-o-transition:all linear .2s;
	-webkit-transition:all linear .2s;
	transition:all linear .2s;
	cursor: pointer;
}
#cookiebanner-close:hover{
	background:theme-color(white);
	color:theme-color(primary);
}
